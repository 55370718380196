
/* eslint-disable */
import qs from 'qs'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { showError } from '~/utils'
import { mapState } from 'vuex'
import inAppBrowser from '~/mixins/in-app-browser'

const FacebookIcon = () => import('~/components/Icons/FacebookIcon.vue')
const GoogleIcon = () => import('~/components/Icons/GoogleIcon.vue')
const LoginForm = () => import('~/components/Login/LoginForm.vue')
const SocialLoginItem = () => import('~/components/Login/SocialLoginItem.vue')

const SocialHead = () => import('~/components/SocialHead/index.vue')
const TwitchIcon = () => import('~/components/Icons/TwitchIcon.vue')
const mainLogo = require('~/assets/images/logo/gank_logo_icon.png')

export default {
  components: {
    FacebookIcon,
    GoogleIcon,
    LoginForm,
    SocialHead,
    SocialLoginItem,
    TwitchIcon,
  },
  mixins: [inAppBrowser],
  layout: 'blank',
  asyncData({ $auth, redirect }) {
    if ($auth.loggedIn) {
      redirect('/')
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('otp/setShowOTPLogin', false)
    next()
  },
  data() {
    return {
      isMounted: false,
      isLogin: true,
      qrcode: null,
      activeTab: '1',
      socialItems: [
        {
          key: 'facebook', title: 'Facebook', icon: 'facebook-icon', dataTestId: 'facebook-signin',
        },
        {
          key: 'google', title: 'Google', icon: 'google-icon', dataTestId: 'google-signin',
        },
        {
          key: 'social', title: 'Twitch', icon: 'twitch-icon', dataTestId: 'twitch-signin',
        },
      ],
    }
  },
  head() {
    const ogData = [
      { property: 'og:title', content: 'Login - Gank' },
      { property: 'og:url', content: `${this.$config.gankUrl + this.$route.path}` },
      { property: 'og:height', content: 200 },
      { property: 'og:width', content: 200 },
      { property: 'og:image', content: `${this.$config.gankUrl + mainLogo}` },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: 'Login - Gank' },
      { name: 'twitter:image', content: `${this.$config.gankUrl + mainLogo}` },
    ]
    return {
      title: 'Login - Gank',
      meta: ogData,
    }
  },
  async mounted() {
    this.isMounted = true
    this.getQRCode()

    if (this.$route.hash) {
      try {
        const removeHash = this.$route.hash.replace('#', '')
        const parseQuery = new URLSearchParams(removeHash)
        const token = parseQuery.get('access_token')
        const authStrategy = this.$auth.$state.strategy === 'social' ? 'twitch' : this.$auth.$state.strategy
        const referralCode = localStorage.getItem('referralCode')
        const {
          data: { data: loginResponse },
        } = await this.$axios.post(`/users/login/${authStrategy}`, {
          token,
          referralFrom: referralCode,
        })
        await this.$auth.setStrategy('local')
        localStorage.removeItem('referralCode')
        const { accessToken } = loginResponse
        const { refreshToken } = loginResponse
        await this.$auth.setUserToken(accessToken, refreshToken)
        const {
          data: { data: user },
        } = await this.$axios.get('/users/me')
        await this.$auth.setUser(user)
        const getPath = localStorage.getItem('loginRedirect') || '/'
        let redirect = getPath
        if (isEmpty(user.nickname)) {
          this.$gtm.push({ event: 'sign_up', method: 'sso', user_id: user.id })
          this.$gtm.push({
            event: `sign_up_${authStrategy}_attempt`,
          })
          localStorage.setItem('sign_up_type', authStrategy)
          redirect = '/signup/username'
        } else if (redirect === '/signup') {
          redirect = '/'
        }

        this.$router.replace(redirect).catch(() => {})
      } catch (error) {
        showError(error)
      }
    }
  },
  computed: {
    ...mapState({
      showOTPLogin: state => state.otp.showOTPLogin,
    }),
  },
  methods: {
    async getQRCode() {
      try {
        this.loading = true
        const {
          data: { data: qrcode },
        } = await this.$axios.get('users/login/qrcode')
        this.qrcode = qrcode
        this.listenLoginEvents()
      } catch (error) {
        showError(error, 'Failed to get QR code')
      }
    },
    async listenLoginEvents() {
      const { id, code, token } = this.qrcode
      const params = {
        id,
        code,
        token,
      }
      try {
        const { data } = await this.$axios.get(`users/login/qrcode/events?${qs.stringify(params)}`)
        const accessToken = get(data, 'data.accessToken')
        await this.$auth.setStrategy('local')
        this.$auth.setUserToken(accessToken)
        const userRes = await this.$axios.get('/users/me', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        this.$auth.setUser(userRes.data.data)
        const getPath = localStorage.getItem('loginRedirect') || '/'
        if (getPath === '/') {
          this.$gtm.push({
            event: 'login_success',
          })
        }
        this.$router.push(getPath).catch(() => {})
      } catch (error) {
        if (
          get(error, 'response.data.errors.message') === 'Event timeout'
          && this.$route.name === 'login'
        ) {
          this.getQRCode()
        }
      }
    },
    handleSocialLogin(key) {
      const option = { params: { prompt: 'select_account' } }
      if (key === 'google') {
        this.$auth.loginWith(key, option)
      } else {
        this.$auth.loginWith(key)
      }
    },
    changeTab(key) {
      this.isLogin = key !== '2'
      if (key === '2') {
        this.$router.push('/signup')
      }
    },
  },
}
