export default {
  data() {
    return {
      userAgent: '',
      isInAppBrowser: false,
      isAndroid: this.$device.isAndroid,
      isIos: this.$device.isIos,
    }
  },
  mounted() {
    this.checkIsInAppBrowser()
    this.handleInAppBrowser()
  },
  methods: {
    /*
     * reference:
     * https://github.com/f2etw/detect-inapp/blob/master/src/inapp.js
     */
    checkIsInAppBrowser() {
      const rules = [
        'WebView',
        '(iPhone|iPod|iPad)(?!.*Safari/)',
        'Android.*(wv)',
      ]
      const regex = new RegExp(`(${rules.join('|')})`, 'ig')

      this.userAgent = navigator.userAgent
      this.isInAppBrowser = Boolean(this.userAgent.match(regex))
    },
    handleInAppBrowser() {
      const isDebugPage = this.$route.name === 'temp-debug'

      if (!isDebugPage && this.isInAppBrowser) {
        let redirectUrl = ''
        const { path } = this.$route

        if (this.isAndroid) {
          redirectUrl = `intent:https://ganknow.com${path}#Intent;end`
        } else if (this.isIos) {
          redirectUrl = `googlechrome://ganknow.com${path}`
        }

        if (redirectUrl) {
          // Redirect to a external browser after a delay (in milliseconds)
          setTimeout(() => {
            window.location.replace(redirectUrl)
          }, 1000)
        }
      }
    },
  },
}
